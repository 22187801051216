import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Language } from "../../Components/Container/Container";
import { useTranslation } from "react-i18next";
// import "../SubCategories/SubCategories.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCloudArrowUp,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
export default function Products() {
  // show image or span
  const [imgSpan, setImgSpan] = useState(false);
  // to show loading when fetch
  const [loading, setLoading] = useState(true);
  // to show Table or Form when click on add or edit button
  const [showTableForm, setShowTableForm] = useState(true);
  // show add button or cancel
  const [addCancel, setAddCancel] = useState(true);
  // to show add button on submit or edit button
  const [bool, setBool] = useState(true);
  //   description app
  const [descriptionApp, setDescriptionApp] = useState("");
  //    app name
  const [nameApp, setNameApp] = useState("");
  // skeleton
  const [skeleton, setSkeleton] = useState(true);

  // id when edit to send it to submit function
  // from container contains which language we use
  const language = useContext(Language);
  // to translate
  const [t] = useTranslation();
  // ***** to fetch *****//
  // save api info in it
  const [todos, setTodos] = useState([]);
  // fetch data
  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "5px",
        }}
      >
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            {...props}
            className="w-100 spanProgress"
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            sx={{ color: "#fff" }}
            variant="body2"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const fetchTodos = async () => {
    setSkeleton(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}admin/apps`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setTodos(response.data.apps);
      // hide loading after fetch
      setLoading(false);
      setSkeleton(false);
    } catch (error) {
      console.error("Error fetching todos:", error);
      // hide loading after fetch
      setSkeleton(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTodos();
  }, []);
  const ShowAddForm = () => {
    // to show add button on submit
    setBool(true);
    // to show span image circle
    setImgSpan(false);
    // show Form hide table
    setShowTableForm(false);
    // show cancel button
    setAddCancel(false);
    // remove Text
    setNameApp("");
    setDescriptionApp("");
    setImages([]);
    setImage();
    setImagesCards([]);
  };
  // start edit form
  const [idEdit, setIdEdit] = useState(null);
  const addEdit = async (e) => {
    setProgress(0);

    try {
      // stop submit refresh
      const formData = new FormData();
      formData.append("name", nameApp);
      formData.append("description", descriptionApp);
      formData.append("main_image", image);
      formData.append("id", idEdit);
      images.map((imageApp, i) => {
        console.log(`images[${i}]`, imageApp);
        formData.append(`images[${i}]`, imageApp);
      });
      e.preventDefault();
      // show add button

      // remove the text from all inputs after added
      setTimeout(() => {
        const inputsUse = document.querySelectorAll(".inputUse");
        inputsUse.forEach((input) => {
          console.log("input");
          input.value = "";
        });
      }, 0);
      const rest = await axios.post(
        `${process.env.REACT_APP_API_URL}admin/apps/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          onUploadProgress: function (event) {
            // console.log(event);
            // if (event.lengthComputable) {
            // حساب نسبة التقدم
            let progressV = Math.round((event.loaded / event.total) * 100);
            setProgress(progressV);
          },
        }
      );

      setAddCancel(true);
      // show table hide form
      setShowTableForm(true);
      // refresh api to show the new
      setImagesCards([]);
      setImages([]);
      setProgress(0);
      fetchTodos();
    } catch (error) {
      console.log("something went wrong", error);
    }
  };
  const ShowEditForm = async (id) => {
    try {
      // to show edit button on submit
      setBool(false);
      // to show img circle that we selected it
      setImgSpan(false);
      // send id to edit button on submit to know which field we selected
      setIdEdit(id);
      // show Form hide table
      setShowTableForm(false);
      // show cancel button
      setAddCancel(false);
      setNameApp("");
      setDescriptionApp("");
      setImages([]);
      setImage();
      setImagesCards([]);
    } catch (error) {
      console.log("something went wrong", error);
    }
  };
  // end edit form
  const [progress, setProgress] = useState(0);
  const addTodo = async (e) => {
    setProgress(0);
    try {
      e.preventDefault();

      const formData = new FormData();
      formData.append("name", nameApp);
      formData.append("description", descriptionApp);
      formData.append("main_image", image);
      images.map((imageApp, i) => {
        console.log(`images[${i}]`, imageApp);
        formData.append(`images[${i}]`, imageApp);
      });
      // add a new info to api
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}admin/apps/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },

          onUploadProgress: function (event) {
            // console.log(event);
            // if (event.lengthComputable) {
            // حساب نسبة التقدم
            let progressV = Math.round((event.loaded / event.total) * 100);
            setProgress(progressV);
          },
        }
      );
      // refresh api in the page
      // if (parseInt(progress) == 100) {
      console.log("object");
      setShowTableForm(true);
      // remove the images on form
      // setImagesCards([]);
      // show table hide form
      // show cancel button
      setAddCancel(true);
      //
      setShowTableForm(true);
      // remove the images on form
      setImagesCards([]);
      setImages([]);

      // remove the text from all inputs after added
      setTimeout(() => {
        const inputsUse = document.querySelectorAll(".inputUse");
        inputsUse.forEach((input) => {
          console.log("input");
          input.value = "";
        });
      }, 0);
      setProgress(0);

      fetchTodos();
      // }
    } catch (error) {
      setProgress(0);

      console.error("Error adding todo:", error);
    }
  };

  const deleteTodo = async (id) => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}admin/apps/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      // refresh api in the page
      fetchTodos();
    } catch (error) {
      console.error("Error deleting todo:", error);
    }
  };
  //   image
  const [image, setImage] = useState("");
  const handleImageChange = async (files) => {
    // the file in input that we uploaded it
    console.log(files);
    const selectedImage = files[0];
    const reader = new FileReader();
    const imgForm = document.querySelector(".imgForm");
    if (selectedImage) {
      reader.onload = function (e) {
        imgForm.src = reader.result; // تحديث src لـ imgForm بقيمة الصورة المحملة
      };
      reader.readAsDataURL(selectedImage);
    }
    // كائن لتخزين الصورة
    // const formData = new FormData();
    // formData.append("image", selectedImage);
    setImgSpan(true);
    // after api we need to edit
    // formData.append("image", selectedImage);
    setImage(selectedImage);
  };
  //   multiple images
  const [images, setImages] = useState([]);
  const [imagesCards, setImagesCards] = useState([]);
  const handleImagesChange = async (event, itemId) => {
    // the file in input that we uploaded it
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => {
      const reader = new FileReader();
      if (image) {
        reader.onload = function (e) {
          // console.log(reader.result);
          setImagesCards((prev) => [...prev, reader.result]);
        };
        reader.readAsDataURL(image);
      }
    });
    // كائن لتخزين الصورة
    // const formData = new FormData();
    // formData.append("image", selectedImage);
    // setImgSpan(true);
    // after api we need to edit
    // formData.append("image", selectedImage);
    setImages((prev) => [...prev, ...selectedImages]);
  };
  console.log(images);
  // add file to make another button that we can to style it
  const clickInputFile = () => {
    const inputFile = document.querySelector('input[type="file"]');
    inputFile.click();
  };

  const Cancel = () => {
    // show table hide form
    setShowTableForm(true);
    // show add button
    setAddCancel(true);
    // remove Text
    setTimeout(() => {
      const inputsUse = document.querySelectorAll(".inputUse");
      inputsUse.forEach((input) => {
        console.log("input");
        input.value = "";
      });
    }, 0);
  };
  const [disabledBool, setDisabledBool] = useState(false);
  // english

  // const validateEnglishInput = (inputValue) => {
  //   return englishPattern.test(inputValue);
  // };
  const [disabledBoolEn, setDisabledBoolEn] = useState(false);
  // console.log(imagesCards);
  const onDragOver = (event) => {
    event.preventDefault();
    // const spanDrag = document.querySelector(".spanDrag");
    const spanDrag = document.querySelector(".spanDrag");
    console.log(spanDrag);
    spanDrag.style.cssText = "background:#7a7a7a3b !important;";
  };
  const onDragLeave = (event) => {
    const spanDrag = document.querySelector(".spanDrag");
    event.preventDefault();
    console.log(spanDrag);
    spanDrag.style.cssText = "";
  };
  const onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleImageChange(files);
    console.log(files, "drag");
  };
  return (
    <>
      <div className="container mx-auto head radius-5 position-relative fontChangeSmall bg-boxes p-2 p-md-3 d-flex align-items-center justify-content-between">
        <h6 className="mb-0 fontChangeSmall">{t("Best selling")}</h6>
        <div className="info d-flex align-items-center justify-content-between gap-2">
          {/*  */}
          {addCancel ? (
            <button
              className="px-2 px-md-3 py-1 radius-8"
              onClick={ShowAddForm}
            >
              {t("add")}
            </button>
          ) : (
            <button
              className="px-2 px-md-3 py-1 radius-8 bg-danger text-white"
              onClick={Cancel}
            >
              {t("cancel")}
            </button>
          )}
        </div>
      </div>
      {/* if true show table false show form */}
      {showTableForm ? (
        <div className="row container mx-auto">
          {skeleton ? (
            <>
              {[...Array(10).keys()].map((item, i) => {
                return (
                  <div className=" col-md-6 col-lg-4 mt-3" key={i}>
                    <div
                      className="card cardApps cardSkeleton"
                      aria-hidden="true"
                    >
                      <p className="card-text placeholder-glow w-100 h-50">
                        <span
                          className="placeholder col-7 d-block w-100 h-100"
                          cardSpan
                        ></span>
                      </p>

                      <div className="card-body">
                        <h5 className="card-title placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </h5>
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-7" cardSpan></span>
                          <span className="placeholder col-4" cardSpan></span>
                          <span className="placeholder col-4" cardSpan></span>
                          <span className="placeholder col-6" cardSpan></span>
                          <span className="placeholder col-8" cardSpan></span>
                        </p>
                        <a
                          href="#"
                          tabindex="-1"
                          className="btn btn-danger disabled placeholder col-6"
                        ></a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : todos.length > 0 ? (
            todos?.map((app) => {
              return (
                <>
                  <div className="col-md-6 col-lg-4 mt-3">
                    <div className="card cardApps w-100 h-100">
                      <img
                        className="card-img-top"
                        style={{ objectFit: "contain", height: "300px" }}
                        src={`${process.env.REACT_APP_API_URL_IMAGE}${app.main_image}`}
                        alt="Card image cap"
                      />
                      <div className="card-body text-center d-flex align-items-center justify-content-around flex-column">
                        <h5 className="card-title">{app.name}</h5>
                        <p className="card-text">{app.description}</p>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <button
                            type="button"
                            className="btn btn-danger w-100"
                            onClick={() => deleteTodo(app.id)}
                          >
                            {t("Delete")}
                          </button>
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => ShowEditForm(app.id)}
                          >
                            {t("Edit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="noData fw-bold text-capitalize text-center position-absolute mainColorText mt-2">
              {t("noData")}
            </div>
          )}
        </div>
      ) : (
        <form
          className=" pb-5 py-3 form fontChangeSmall d-flex flex-column justify-content-center align-items-center "
          onSubmit={bool ? addTodo : addEdit}
        >
          {/* {imgSpan ? ( */}
          <label htmlFor="drag" className="position-relative labelImage">
            <img
              alt={"no Image"}
              src={``}
              className={`mb-2 imgForm ${imgSpan ? "d-block" : "d-none"}`}
              // onClick={clickInputFile}
            />
            <span
              className={`spanForm ${
                imgSpan ? "d-none" : "d-grid"
              } fs-5 spanDrag`}
              // onClick={clickInputFile}
            >
              <FontAwesomeIcon
                icon={faCloudArrowUp}
                style={{ color: "var(--clr-product)" }}
                size="4x"
              />
            </span>
            <input
              className={`${language == "ar" ? "ar" : ""} inputUse`}
              type="file"
              accept="image/*"
              onChange={(event) => handleImageChange(event.target.files)}
              // onDrag={(event)}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragLeave={onDragLeave}
              id="drag"
              required
            />
          </label>

          <input
            onChange={(e) => setNameApp(e.target.value)}
            className="changeWidth arabic w-100 border-1 mb-2 p-4 inputUse productInput"
            type="text"
            value={nameApp}
            placeholder={t("appName")}
            required
          />

          {disabledBool && (
            <p className="mainColorText fw-bold fs-6">{t("justArabic")}</p>
          )}
          <textarea
            onChange={(e) => setDescriptionApp(e.target.value)}
            className="textareaProduct w-100 border-1 mb-2 p-4 inputUse productInput"
            name=""
            id=""
            cols="30"
            rows="10"
            required
            placeholder={t("appDescription")}
            value={descriptionApp}
          ></textarea>
          {/* <input type="text" required /> */}
          {bool ? (
            <>
              <input
                className="changeWidth btnForDelete w-100  border-0 text-white p-4 mainColorBg mb-2"
                type="submit"
                value={t("add")}
                disabled={
                  disabledBool === true || disabledBoolEn === true
                    ? true
                    : false
                }
              />
            </>
          ) : (
            <input
              className="changeWidth btnForDelete w-100  border-0 text-white p-4 mainColorBg mb-2"
              type="submit"
              value={t("Edit")}
              disabled={
                disabledBool === true || disabledBoolEn === true ? true : false
              }
            />
          )}
          {/* <div className="d-flex justify-content-start align-items-center"> */}
          <div className="row w-100">
            {imagesCards.length
              ? // <div className="imagesProducts container mx-auto">
                imagesCards.map((image) => {
                  return (
                    <div className="col-6">
                      <div className="image h-100 ">
                        <img
                          src={`${image}`}
                          alt="no "
                          className="w-100 radius-8 h-100"
                        />
                        ;
                      </div>
                    </div>
                  );
                })
              : // </div>
                ""}
            <div className="col-6 d-flex align-items-center">
              <label
                htmlFor="images"
                className="spanFormImages position-relative w-100 "
              >
                {/* {t("images")}+ */}
                <FontAwesomeIcon icon={faAdd} size="2x" />
                <input
                  id="images"
                  className={`${language == "ar" ? "ar" : ""} `}
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(event) => handleImagesChange(event)}
                  required
                />
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2 w-100">
            <LinearProgressWithLabel
              value={progress}
              color="primary"
              // className="w-100"
            />
            {/* <span className="text-white">{progress}%</span> */}
          </div>
        </form>
      )}
    </>
  );
}
